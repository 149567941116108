<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">

       <b-link class="brand-logo" >
          <h2 class="brand-text text-success ml-0">
            N9VA
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Email confirmado!
        </b-card-title>
        <b-card-text class="mb-2">
          Seu email foi confirmado e seu acesso já foi liberado!
        </b-card-text>

        <p class="text-center mt-2">
          <b-link :to="{path:'/'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para login
          </b-link>
        </p>
      </b-card>
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      token: '',
    }
  },
  created(){
    this.token = new URL(window.location.href).searchParams.get('token');
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
